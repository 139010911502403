<template>
  <div>
    <div
      id="offcanvasChapters"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasResearchLineLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="user-plus" /></span>
          Propose new peer {{ blacklist ? 'to blacklist' : '' }}
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas laudantium adipisci, dolorum facilis sint perferendis aliquam, pariatur explicabo ad corporis aliquid fugiat debitis esse odit, quis optio reprehenderit est repudiandae. <strong><a
          href="#"
          class="text-primary"
          target="_blank"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Go to knowledge base"
        >More information</a></strong>.</p>
        <hr> -->
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Name <span class="text-danger">*</span></label>
          <input
            v-model="user.first_name"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Surname <span class="text-danger">*</span></label>
          <input
            v-model="user.last_name"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Institution <span class="text-danger">*</span></label>
          <input
            v-model="user.institution_name"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Email <span class="text-danger">*</span></label>
          <input
            v-model="user.email"
            type="email"
            class="form-control"
          >
        </div>
      </div>
      <div class="offcanvas-footer mt-auto">
        <!-- <a
          href=""
          data-bs-toggle="modal"
          data-bs-target="#modalProposedPeer"
        >example modal send</a> -->
        <button
          type="button"
          class="btn btn-success mb-1 d-grid w-100"
          @click="proposeEvaluator"
        >
          Send
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    tableId: {
      type: Number,
      required: false,
      default: null,
    },
    blacklist: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      user: {
        name: 'aa',
      },
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/proposeEvaluator',
      tableClicked: 'modals/tableClicked',
      userTrue: 'users/user',
      loggedUser: 'auth/admin',
    }),
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleProposeEvaluator', false)
    },
    async proposeEvaluator() {
      try {
        if (this.user.first_name.trim() && this.user.last_name.trim() && this.user.institution_name.trim() && this.user.email.trim()) {
          if (this.user.email === this.loggedUser.email) {
            Vue.swal('You can\'t propose yourself.', '', 'warning')
          } else {
            await this.$store.dispatch('users/proposePeer', { user: this.user, authUser: this.userTrue, blacklist: this.blacklist })
            this.$toastr.success('', `Evaluator ${this.blacklist ? 'blacklisted' : 'proposed'} successfully!`)
            this.$store.dispatch('users/filterEvaluators')
            this.$store.dispatch('users/getPeers', this.userTrue.id)
            this.closeOffcanva()
          }
        } else {
          Vue.swal('Please, fill up all the required fields', '', 'warning')
        }
      } catch (e) {
        Vue.swal('Please, fill up all the required fields', '', 'warning')
      }
    },
  },
}
</script>
